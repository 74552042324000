<template>
    <div class="withdrawList-main">
        <div class="mian-section">
            <p class="warnning-title">{{$t("myWallet.selectWailetorder")}}</p>
            <div class="withdraw-list">
                <div class="list-item" v-for="(item,index) in withdrawList" :key="index">
                    <div class="chexk-box" @click="selectCheck(index)">
                        <i class="check-icon" v-if="!item.checkType"></i>
                        <img class="active-check" v-else src="@/assets/img/withdraw-active.png">
                    </div>
                    <div class="item-right">
                        <div class="order-price flex">
                            <p class="left">{{ $t("myWallet.WailetOrder",{price:item.orderPayMoney})}}</p>
                            <p class="right">
                                <span class="txt">{{$t("myWallet.cashBack")}}</span>
                                <span class="price-txt">+{{item.money}}</span>
                            </p>
                        </div>
                        <p class="order-time">{{$t("myWallet.orderCreatedTime")}}{{getLocalMinutesTime(item.orderPaySuccessDt)}}</p>
                    </div>
                </div>
            </div>
            <div class="nodata" v-if="withdrawList.length==0">
                <img src="@/assets/img/data-null.png" alt="">
            </div>
        </div>
        <div class="mian-bottom">
            <div class="all-box">
                <p class="check">
                    <span class="check-icon" @click="allCheck">
                        <i class="icon" v-if="!allType"></i>
                        <img class="active-check" v-else src="@/assets/img/withdraw-active.png">
                    </span>
                    <span class="txt">{{$t("myWallet.allSelect")}}</span>
                </p>
                <p class="withdra-price">
                    {{$t("myWallet.haveEvlectWithdrawalMoney")}} <span style="color:#E52C1B;">{{allPrice}}</span> {{$t("comonTxt.money")}}
                </p>
            </div>
            <p class="btn-bottom" @click="ToWithdraw">{{$t("myWallet.nextstep")}}</p>
        </div>
    </div>
</template>

<script>
import { moneyQuery, getWxUserOpenId } from "@/api/shop";
import { getLocalMinutesTime } from "@/utils/date";

export default {
    data(){
        return{
            openId:'',
            withdrawList:[],
            getLocalMinutesTime
        }
    },
    async created(){
        document.title = this.$t("myWallet.canWithdrawList");
        await this.getOpenId();
        this.getWithdrawData()
    },
    computed:{
        allPrice:function(){
            let price = 0; 
            for(let it in this.withdrawList){
                if(this.withdrawList[it].checkType){
                    price += this.withdrawList[it].money;
                }
            }
            return price.toFixed(2);
        },
        allType:function(){
            let type = false;
            let num = 0;
            for(let it in this.withdrawList){
                if(this.withdrawList[it].checkType){
                    num++;
                }
            }
            if(num == this.withdrawList.length&&this.withdrawList.length!=0){
                type = true;
            }
            return type
        }
    },
    methods:{
        async getOpenId(){
            let datadd = {};
            let result = await getWxUserOpenId(datadd);
            if(result.data.errorCode == 0){
                this.openId = result.data.data;
            }
        },
        selectCheck(index){
            this.withdrawList[index].checkType = !this.withdrawList[index].checkType;
        },
        allCheck(){
            let alltype = this.allType; 
            for(let it in this.withdrawList){
                if(alltype){
                    this.withdrawList[it].checkType = false;
                }else{
                    this.withdrawList[it].checkType = true;
                }
            }
        },
        ToWithdraw(){
            let activeArr = [];
            for(let it in this.withdrawList){
                if(this.withdrawList[it].checkType){
                    activeArr.push(this.withdrawList[it].id)
                }
            }
            if(activeArr.length == 0){
                this.$toast(this.$t("myWallet.pleaseSelectWailetOrder"));
                return false;
            }
            this.$router.push({
                path:"/my/applyForwallet",
                query:{
                    allPrice:this.allPrice,
                    userMoneys:activeArr
                }
            })
        },
        async getWithdrawData(){
            let datadd = {
                statusList:[130003],
                pageNum:1,
                pageSize:10000
            };
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await moneyQuery(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                for(let it in result.data.data.data){
                    result.data.data.data[it].checkType = false;
                }
                this.withdrawList = result.data.data.data;
            }else{
                this.withdrawList = [];
            }
        },

    }
}
// withdraw-active.png
</script>
<style lang="scss" scoped>
.withdrawList-main{
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    .mian-section{
        flex: 1;
        overflow: auto;
        .warnning-title{
            height: 1.6rem;
            line-height: 1.6rem;
            font-size: .52rem;
            font-weight: 400;
            color: #838385;
            padding: 0 .6rem;
            background: #F5F6FA;
        }
        .nodata{
            height: calc(100% - 1.6rem);
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 8rem;
            }
        }
        .withdraw-list{
            background: #fff;
            padding: 0 .6rem;
            .list-item{
                display: flex;
                align-items: center;
                padding: .6rem 0;
                border-bottom: .02rem solid #F5F6FA;
                .chexk-box{
                    flex: auto 0 0;
                    padding: .2rem;
                    margin-right: .3rem;
                    .check-icon,.active-check{
                        width: .48rem;
                        height: .48rem;
                        border: .02rem solid #979797;
                        border-radius: 50%;
                        display: block;
                    }
                    .active-check{
                        border:0;
                        width: .52rem;
                        height: .52rem;
                    }
                }
                .item-right{
                    flex: 1;
                    .order-price{
                        display: flex;
                        justify-content: space-between;
                        .left{
                            font-size: .56rem;
                            font-weight: 500;
                            color: #303133;
                            line-height: .8rem;
                        }
                        .right{
                            font-weight: 500;
                            color: #FF8039;
                            line-height: .8rem;
                            .txt{
                                font-size: .56rem;
                            }
                            .price-txt{
                                font-size: .64rem;
                            }
                        }
                    }
                    .order-time{
                        font-size: .56rem;
                        font-weight: 400;
                        color: #838385;
                        line-height: .8rem;
                        margin-top: .2rem;
                    }
                }
            }
        }
    }
    .mian-bottom{
        flex: auto 0 0;
        .all-box{
            height: 1.8rem;
            background: #D8E8FE;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 .6rem;
            .check{
                display: flex;
                align-items: center;
                .check-icon{
                    margin-right: .2rem;
                    padding: .1rem;
                    .icon,.active-check{
                        width: .48rem;
                        height: .48rem;
                        border: .02rem solid #979797;
                        border-radius: 50%;
                        display: block;
                    }
                    .active-check{
                        border:0;
                        width: .52rem;
                        height: .52rem;
                    }
                }
                .txt{
                    font-size: .52rem;
                    font-weight: 500;
                    color: #303133;
                }
            }
            .withdra-price{
                font-size: .52rem;
                font-weight: 400;
                color: #838385;
                line-height: .8rem;
            }
        }
        .btn-bottom{
            height: 1.8rem;
            line-height: 1.8rem;
            text-align: center;
            background: linear-gradient(180deg, #579CFF 0%, #3186F8 100%);
            border-radius: 0.1rem;
            font-size: .64rem;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}
</style>